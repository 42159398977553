@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Raleway:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Padauk:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&family=Orbitron:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600;700&display=swap");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

body {
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Montserrat", sans-serif;
  color: #1f1f1f;
  scroll-behavior: smooth;
}
.modal {
  background-color: white;
  border-radius: 5px;
  color: #333;
  font-family: sans-serif;
  padding: 1rem 2rem;
}
.admin-banner-h {
  font-family: "Montserrat", sans-serif;
}
.img {
  width: 100%;
  max-width: 100%;
}

.p-calendar button {
  background-color: "#007BFF";
}
.home_button {
  background: #0762ca;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.login_page {
  background-image: url("./assets//images/Circuit\ Board.svg");
}
.home_page {
  padding-top: 100px;
  background-image: url("./assets//images/Animated\ Shape-3.svg");
  background-color: white;
}
#selectStation {
  background-image: url("./assets//images/Animated\ Shape-3.svg");
}
#circle_card {
  background: rgb(54, 78, 124);
  background: radial-gradient(
    circle,
    rgb(85, 134, 226) 0%,
    rgb(50, 50, 156) 99%,
    rgb(75, 17, 192) 100%
  );
}
#square_card {
  background: rgb(92, 175, 147);
  background: radial-gradient(
    circle,
    rgb(121, 239, 200) 0%,
    rgb(46, 142, 96) 99%,
    rgb(9, 223, 130) 100%
  );
}
#digital {
  font-family: "Orbitron", sans-serif;
}
/* table css */
table {
  color: #383838;
  font-family: "Inter", sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}

td,
th {
  /* border: 0.1px solid rgb(195, 195, 195); */
  border: 1px solid #e0dfdfac;
  /* border:0.5px solid rgb(9, 9, 9); */
  text-align: left;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #e9e9e9;
  color: #4a4a4a;
  height: 80px;
}

tr:nth-child(even) {
  /* background-color: #e9e7e7a8; */
}

tr:hover {
  /* background-color: #f5fcff; */
  cursor: pointer;
}

#category_table {
  font-family: "Inter", sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}

#category_table td,
#category_table th {
  /* border: 0.1px solid rgb(195, 195, 195); */
  border: 1px solid rgb(179, 179, 179);
  padding: 8px;
  height: 50px;
}

#category_table tr:hover {
  background-color: white;
}

#detailSale_table {
  font-family: "Inter", sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  font-weight: bolder;
}

#detailSale_table td,
#detailSale_table th {
  /* border: 0.1px solid rgb(195, 195, 195); */
  border: 1px solid rgba(234, 234, 234, 0.509);
  padding: 15px;
  height: 60px;
  text-align: left;
}

#detailSale_table tr:hover {
  background-color: white;
}

/* table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table td,
table th {
    border: 1px solid rgb(228, 224, 224);
    padding: 8px;
    padding: 15px;
    background-color: white;
}

table tr:nth-child(even) {
    background-color: #e26060;
}

table tr:hover {
    background-color: #c9c9c9;
}

table th, tfoot td{
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    text-align: center;
    background-color: white;
    color: black;
} */

#dailyButton:disabled,
#fuelButton:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.neumorphism_card {
  border-radius: 21px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 35px 35px 70px #a8a8a8, -35px -35px 70px #ffffff;
}

.neumorphism_circle {
  border-radius: 50%;
  background: linear-gradient(145deg, #d4d4d4, #fbfbfb);
  box-shadow: 25px 25px 50px #b0b0b0, -25px -25px 50px #ffffff;
}

#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border: solid 5px transparent;
  border-top-color: rgb(37, 63, 180) !important;
  border-left-color: rgb(37, 62, 171) !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#HomeCard {
  animation: ease-in-out 0.7s popup;
}

@keyframes popup {
  from {
    transform: translateY(80px);
    opacity: 0.5;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.our-problem {
  display: inline-block;
  background-color: #ff6a00;
  background: -webkit-linear-gradient(
    0deg,
    oklch(67.3% 0.266 25.039656026515278),
    oklch(85.82% 0.201 91.19),
    #ca196f
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: phaser 3s infinite;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  font-weight: bolder;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.loader-line {
  width: 200px;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 100px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: coral;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.bann_button {
  border-radius: 78px;
  background: #d71d1d;
  box-shadow: 15px 15px 29px #c41a1a, -15px -15px 29px #ea2020;
}
.bann_button_active {
  border-radius: 78px;
  background: #159013;
  box-shadow: 20px 20px 60px #127a10, -20px -20px 60px #18a616;
}

.bann_button_inactive {
  border-radius: 78px;
  background: #e0e0e0;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.p-datepicker {
  border: 1px solid black;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

@keyframes phaser {
  0% {
    background-position: 0% 0;
  }

  100% {
    background-position: 100% 0;
  }
}
